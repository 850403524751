<template>
  <div>
    <Header />

    <section class="section1">
      <div class="text-box">
        <div class="h4">数据分析</div>
        <div class="desc mb-2">
          下单未付款分析 / 付款周期分析 / 发货周期分析 / 订单来源分析 / 收货行为分析 / 地域分析
        </div>
        <div class="desc mb-2">
          会员等级分析 / 复购分析 / 新老会员分析 / 新会员增长趋势分析
        </div>
        <div class="desc mb-2">
          商品类目分析 / 商品销售分析
        </div>
        <div class="desc mb-2">
          RFM分析
        </div>
      </div>
    </section>

    <section>
      <div class="container container-space-y-s">
        <device-browser style="width: 800px;">
          <img style="width: 100%;"
               src="~@/assets/images/crm_analyze_01.png">
        </device-browser>
      </div>
    </section>

    <section>
      <div class="container container-space-y-s">
        <device-browser style="width: 800px;"
                        shadow="2">
          <img style="width: 100%;"
               src="~@/assets/images/crm_analyze_02.png">
        </device-browser>
      </div>
    </section>

    <section>
      <div class="container container-space-y-s">
        <device-browser style="width: 800px;"
                        shadow="3">
          <img style="width: 100%;"
               src="~@/assets/images/crm_analyze_03.png">
        </device-browser>
      </div>
    </section>

    <section>
      <div class="container container-space-y-s">
        <device-browser style="width: 800px;"
                        shadow="4">
          <img style="width: 100%;"
               src="~@/assets/images/crm_analyze_04.png">
        </device-browser>
      </div>
    </section>
    <BannerRegistration />
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/header'
import Footer from '@/components/footer'
import DeviceBrowser from '@/components/device-browser'
import BannerRegistration from '@/components/banner-registration'
export default {
  name: 'Analyze',
  components: {
    Header,
    Footer,
    DeviceBrowser,
    BannerRegistration
  }
}

</script>

<style lang="scss" scoped>
.section1 {
  position: relative;
  width: 100%;
  height: 450px;
  background: url("~@/assets/images/banner05.png");
  background-size: 100%;
  background-position: center;
  color: #fff;

  .text-box {
    position: absolute;
    left: 16%;
    top: 100px;

    .desc {
      color: #c5c5c5;
    }
  }
}
</style>
